//react import
import React from "react";

// stylesheet import
import style from "../../assets/styles/projects.module.css";

//react-boostrap imports
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";

//framer motion
import { motion } from "framer-motion";

const cardVariants = {
  offscreen: {
    y: 100,
    rotate: 5,
  },
  onscreen: {
    y: 0,
    rotate: 0,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.6,
    },
  },
};

export default function ProjectPage({ item }) {
  return (
    <>
      <Col
        xl={4}
        className={`${style.card_wrapper} d-flex align-items-center justify-content-center w-100`}
      >
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.5 }}
          variants={cardVariants}
          className="w-100"
        >
          <div
            className={`${style.gradient_box_right} d-flex align-items-center justify-content-center flex-column`}
            style={{ borderRadius: "36px 36px 0 36px" }}
          >
            <img
              style={{ borderRadius: "18px 18px 0px 0px" }}
              className="w-100"
              src={item.image}
              alt={item.title}
            />
            <p
              className={`${style.card_title} my-3`}
              style={{ marginRight: "auto" }}
            >
              {item.title}
            </p>
            <p className={`${style.card_text} mb-0`}>{item.text}</p>
            <Stack
              className={`${style.tech_stack}`}
              direction="horizontal"
              gap={2}
            >
              {item.github !== "" && (
                <a href={item.github} target="_blank" rel="noreferrer">
                  <i class="devicon-github-original"></i>
                </a>
              )}

              {item.hosting !== "" && (
                <a href={item.hosting} target="_blank" rel="noreferrer">
                  <p>🔗</p>
                </a>
              )}

              {item.techStackSrc.map((srcValue, index) => {
                if (index === 0) {
                  return (
                    <img
                      src={srcValue}
                      alt="tech stack"
                      style={{ marginLeft: "auto" }}
                    />
                  );
                } else {
                  return <img src={srcValue} alt="tech stack" />;
                }
              })}
            </Stack>
          </div>
          <div
            className={`${style.grey_outline_box_right}`}
            style={{ borderRadius: "36px 36px 0 36px" }}
          ></div>
          <div className={`${style.blob_2}  d-none d-xl-block`}>
            <svg
              width="100%"
              viewBox="0 0 1000 1000"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient
                  id="linearGradientId"
                  gradientTransform="rotate(-45 0.5 0.5)"
                >
                  <stop offset="0%" stop-color="#fd69ec" />
                  <stop offset="33.333333333333336%" stop-color="#df32fd" />
                  <stop offset="66.66666666666667%" stop-color="#00c2ff" />
                  <stop offset="100%" stop-color="#00ffd4" />
                </linearGradient>
              </defs>

              <g clip-path="url(#shape)">
                <path fill="url(#linearGradientId)">
                  <animate
                    attributeName="d"
                    dur="15s"
                    repeatCount="indefinite"
                    values="
                          M770,640Q780,780,640,795Q500,810,394.5,760.5Q289,711,243.5,605.5Q198,500,229,380Q260,260,380,168Q500,76,623,165Q746,254,753,377Q760,500,770,640Z;
                          M819.5,609.5Q719,719,609.5,769Q500,819,323.5,836Q147,853,198.5,676.5Q250,500,227.5,352.5Q205,205,352.5,172Q500,139,611.5,208Q723,277,821.5,388.5Q920,500,819.5,609.5Z;
                          M781,596Q692,692,596,773.5Q500,855,374,803.5Q248,752,179,626Q110,500,206,401Q302,302,401,246.5Q500,191,619,226.5Q738,262,804,381Q870,500,781,596Z;
                          M913.5,664.5Q829,829,664.5,831Q500,833,365,801.5Q230,770,189.5,635Q149,500,149.5,325Q150,150,325,168Q500,186,675,168Q850,150,924,325Q998,500,913.5,664.5Z;
                          M783,627Q754,754,627,826Q500,898,402.5,796.5Q305,695,169,597.5Q33,500,161.5,395Q290,290,395,232Q500,174,647,190Q794,206,803,353Q812,500,783,627Z;
                          M819.5,609.5Q719,719,609.5,769Q500,819,323.5,836Q147,853,198.5,676.5Q250,500,227.5,352.5Q205,205,352.5,172Q500,139,611.5,208Q723,277,821.5,388.5Q920,500,819.5,609.5Z;
                          M770,640Q780,780,640,795Q500,810,394.5,760.5Q289,711,243.5,605.5Q198,500,229,380Q260,260,380,168Q500,76,623,165Q746,254,753,377Q760,500,770,640Z;
                          "
                  ></animate>
                </path>
              </g>
            </svg>
          </div>
        </motion.div>
      </Col>
    </>
  );
}
