//react import
import React, { useEffect, useRef } from "react";

//stylesheet import
import style from "../assets/styles/connect.module.css";

//react-bootstrap imports
import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";

//assets import
import OwlVector from "../assets/images/owl_vector.svg";

//framer-motion import
import { motion, useTransform, useScroll } from "framer-motion";

export default function Connect() {
  const canvasRef = useRef(null);
  const scrollRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ["start end", "start center"],
  });

  const opacity = useTransform(scrollYProgress, [0, 0.7, 1], [0, 0, 1]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let requestId;
    let circles = [];

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    const colors = ["#00ffd4", "#fd69ec", "#00c2ff", "#df32fd"];

    const generateCircle = () => {
      const x = Math.random() * canvas.width;
      const y = Math.random() * canvas.height;
      const radius = Math.random() * 80 + 140; // Random radius >100
      const color = colors[Math.floor(Math.random() * colors.length)]; // Random color out of the given colors
      const velocity = {
        x: Math.random() * 1 - 0.5, //speed variables
        y: Math.random() * 1 - 0.5, //speed variables
      };

      return { x, y, radius, color, velocity };
    };

    const createCircles = () => {
      circles = [];
      const numCircles = Math.floor(Math.random() * 20) + 80; // Random number of circles > 30
      for (let i = 0; i < numCircles; i++) {
        circles.push(generateCircle());
      }
    };

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      for (const circle of circles) {
        ctx.beginPath();
        ctx.arc(circle.x, circle.y, circle.radius, 0, Math.PI * 2);
        ctx.fillStyle = circle.color;

        // Apply the filter style to the circle

        ctx.fill();
        ctx.closePath();

        circle.x += circle.velocity.x;
        circle.y += circle.velocity.y;

        // Keep the circles within the canvas bounds
        if (
          circle.x > canvas.width - circle.radius ||
          circle.x < circle.radius
        ) {
          circle.velocity.x *= -1;
        }
        if (
          circle.y > canvas.height - circle.radius ||
          circle.y < circle.radius
        ) {
          circle.velocity.y *= -1;
        }
      }

      requestId = requestAnimationFrame(draw);
    };

    resizeCanvas();
    createCircles();
    draw();

    window.addEventListener("resize", () => {
      resizeCanvas();
      createCircles();
    });

    return () => {
      cancelAnimationFrame(requestId);
      window.removeEventListener("resize", () => {
        resizeCanvas();
        createCircles();
      });
    };
  }, []);

  return (
    <>
      <motion.div ref={scrollRef} style={{ opacity }}>
        <Container className={`${style.connect_section} p-0`} fluid>
          <div className={`${style.glassmorphic_overlay} w-100`}>
            <Stack
              direction="vertical"
              className={`${style.connect_content_div} justify-content-center`}
            >
              <p className={`${style.connect_title} mb-0`}>Connect</p>
              <p className={`${style.connect_title} mb-0`}>with me!</p>
              <div>
                {" "}
                <p className={`${style.connect_subtxt} mb-0`}>
                  Have a startup idea or wanna talk something product or dev?
                  Let’s connect!
                </p>
              </div>
              <Stack
                direction="horizontal"
                gap={3}
                className={`${style.link_div} align-items-center`}
              >
                <a href="mailto:gjoshi.0522@gmail.com" target="_parent">
                  EM
                </a>
                <p className="mb-0" style={{ color: "#000" }}>
                  -
                </p>
                <a
                  href="https://twitter.com/FreakyTalons"
                  target="_blank"
                  rel="noreferrer"
                >
                  TW
                </a>
                <p className="mb-0" style={{ color: "#000" }}>
                  -
                </p>
                <a
                  href="https://www.linkedin.com/in/joshi-gauri/"
                  target="_blank"
                  rel="noreferrer"
                >
                  LI
                </a>
                <p className="mb-0" style={{ color: "#000" }}>
                  -
                </p>
                <a
                  href="https://github.com/FreakyTalons"
                  target="_blank"
                  rel="noreferrer"
                >
                  GH
                </a>
                <p className="mb-0" style={{ color: "#000" }}>
                  -
                </p>
                <a
                  href="https://medium.com/@gauriJoshi3012"
                  target="_blank"
                  rel="noreferrer"
                >
                  ME
                </a>
              </Stack>
            </Stack>
            <img
              className={`${style.owl_img}`}
              alt="FreakyTalons"
              src={OwlVector}
            />
          </div>
          <canvas className={`${style.canvas_styles}`} ref={canvasRef}></canvas>
        </Container>
      </motion.div>
    </>
  );
}
//className={`${style.canvas_styles}`}
