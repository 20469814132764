//stylesheet import
import style from "../assets/styles/skills.module.css";

//react import
import React, { useRef } from "react";

//assets import
import KeyboardImg from "../assets/images/keyboard_full.svg";

//framer-motion import
import { motion, useTransform, useScroll } from "framer-motion";

//react-bootstrap import
import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";

const KeyboardDiv = () => {
  const scrollRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ["start end", "end start"],
  });

  const opacity = useTransform(
    scrollYProgress,
    [0, 0.5, 0.65, 1],
    [0, 1, 0, 0]
  );

  const scale = useTransform(scrollYProgress, [0, 0.5, 1], [0.3, 1.2, 1.2]);

  return (
    <>
      <motion.div
        ref={scrollRef}
        className={`${style.keyboard_wrapper} w-100 align-items-center justify-content-center flex-column`}
        style={{
          opacity,
          scale,
        }}
      >
        <img
          className={`${style.keyboard_img}`}
          src={KeyboardImg}
          alt="tech stack keyboard"
        />
      </motion.div>
    </>
  );
};

const SkillsList = () => {
  const scrollRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ["start end", "end center"],
  });

  const opacity = useTransform(
    scrollYProgress,
    [0, 0.5, 0.65, 1],
    [0, 1, 1, 0]
  );

  return (
    <motion.div
      ref={scrollRef}
      style={{
        opacity,
      }}
    >
      <div
        className={`${style.skills_icons_wrapper} w-100 align-items-center justify-content-center `}
      >
        <Stack className={` mx-4 justify-content-center`}>
          <p className={`${style.skills_title} mb-2`}>⚙️Languages</p>
          <Stack
            direction="horizontal"
            className={`${style.logo_stack} flex-wrap align-items-center `}
            gap={2}
          >
            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg"
              alt="javascript"
            />

            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg"
              alt="typescript"
            />

            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original-wordmark.svg"
              alt="Java"
            />

            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg"
              alt="python"
            />

            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/c/c-original.svg"
              alt="c"
            />
            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/cplusplus/cplusplus-original.svg"
              alt="c++"
            />

            <i class="devicon-mysql-plain"></i>
          </Stack>
          <p className={`${style.skills_title} mb-2`}>💻MERN Development</p>
          <Stack
            direction="horizontal"
            className={`${style.logo_stack} flex-wrap align-items-center `}
            gap={2}
          >
            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg"
              alt="html"
            />

            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg"
              alt="css"
            />

            <i class="devicon-nextjs-original"></i>
            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg"
              alt="react"
            />

            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redux/redux-original.svg"
              alt="redux"
            />

            <i class="devicon-express-original"></i>

            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg"
              alt="node"
            />

            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-plain-wordmark.svg"
              alt="mongodb"
            />
          </Stack>
          <p className={`${style.skills_title} mb-2`}>⚒️Other Tools</p>
          <Stack
            direction="horizontal"
            className={`${style.logo_stack} flex-wrap align-items-center `}
            gap={2}
          >
            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/npm/npm-original-wordmark.svg"
              alt="npm"
            />

            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/materialui/materialui-original.svg"
              alt="material ui"
            />

            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg"
              alt="bootstrap"
            />

            <i class="devicon-tailwindcss-plain colored"></i>

            <i class="devicon-sass-original"></i>
            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg"
              alt="git"
            />

            <i class="devicon-github-original"></i>
          </Stack>
          <p className={`${style.skills_title} mb-2`}>🖌️Design Tools</p>
          <Stack
            direction="horizontal"
            className={`${style.logo_stack} flex-wrap align-items-center `}
            gap={2}
          >
            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg"
              alt="figma"
            />

            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/xd/xd-plain.svg"
              alt="xd"
            />
          </Stack>
        </Stack>
      </div>
    </motion.div>
  );
};

export default function Keyboard() {
  return (
    <>
      <Container
        style={{
          position: "relative",
          width: "100%",
          height: "100vh",
          overflowX: "hidden",
        }}
        className={`${style.container_styles_responsive}`}
        fluid
      >
        <KeyboardDiv />
        <SkillsList />
      </Container>
    </>
  );
}
