//react imports
import React from "react";

//framer-motion imports
import { motion, useScroll } from "framer-motion";

//stylesheet import
import style from "../assets/styles/hero.module.css";

export default function ProgressBar() {
  const { scrollYProgress } = useScroll();

  return (
    <>
      <motion.div
        className={`${style.progress_bar}`}
        style={{ scaleX: scrollYProgress }}
      />
    </>
  );
}
