//react import
import React from "react";

//react bootstrap imports
import "bootstrap/dist/css/bootstrap.min.css";

//Components import
import Hero from "./Components/Hero";
import Skills from "./Components/Skills";
import ProgressBar from "./Components/ProgressBar";
import Keyboard from "./Components/Keyboard";
import ProjectPortfolio from "./Components/ProjectPortfolio";
import Connect from "./Components/Connect";
import ProjectPage from "./Components/ProjectPage";
import BlogsVids from "./Components/BlogsVids";
import BlogsVidsPage from "./Components/BlogVidsPage";

function App() {
  return (
    <>
      <ProgressBar />
      <Hero />
      <Skills />
      <Keyboard />
      <ProjectPortfolio />
      <ProjectPage />
      <BlogsVids />
      <BlogsVidsPage />
      <Connect />
    </>
  );
}

export default App;
