//react import
import React, { useRef } from "react";

// stylesheet import
import style from "../assets/styles/projects.module.css";

//react-boostrap imports
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

//Components import
import ProjectCardLeft from "./ProjectCards/ProjectCardLeft";
import ProjectCardRight from "./ProjectCards/ProjectCardRight";

//asset import
import AnalyticsPage from "../assets/images/AnalyticsPage.png";
import RestAPI from "../assets/images/restAPI.png";
import SpeedyHome from "../assets/images/SpeedyHome.png";
import NextImg from "../assets/images/nextjs-white.svg";
import Decentrify from "../assets/images/Decentrify.png";
import BlogEditor from "../assets/images/BlogEditor.png";
import SimpliHR from "../assets/images/SimpliHR.png";
import EcomInterface from "../assets/images/ecom.png";
import SpinWheel from "../assets/images/SpinWheel.png";

//framer-motion import
import { motion, useTransform, useScroll } from "framer-motion";

const CSS_src =
  "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg";

const React_src =
  "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg";

const Redux_src =
  "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redux/redux-original.svg";

const Bootstrap_src =
  "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg";

const Python_src =
  "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg";

const Firebase_src =
  "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain.svg";

const OpenCV_src =
  "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/opencv/opencv-original.svg";

const FastAPI_src =
  "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/fastapi/fastapi-original.svg";

const HTML_src =
  "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg";

const JS_src =
  "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg";

const Solidity_src =
  "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/solidity/solidity-plain.svg";

const Node_src =
  "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg";

const Tailwind_src =
  "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-plain.svg";

const leftCardProjects = [
  {
    title: " Speedybrand - Dashboard",
    image: SpeedyHome,
    text: "Delivered in the capacity of the solo frontend developer the MVP of the Speedy dashboard. Speedy is a YCombinator funded start-up; they utilise genrative AI to create tailor made SEO optimised content for marketting.",
    github: "",
    hosting: "https://speedybrand.io/",
    techStackSrc: [NextImg, Bootstrap_src, RestAPI, Python_src],
  },
  {
    title: "Analytics Page",
    image: AnalyticsPage,
    text: "Developed a tool for analytics of performance marketing data. It uses a custom React Table component that can re-ordered and toggle column view, sort and filter entire data according to a column, search values in data.",
    github: "https://github.com/FreakyTalons/analyticspage-mock",
    hosting: "https://analyticspage-mock.vercel.app/",
    techStackSrc: [React_src, Redux_src, RestAPI, CSS_src],
  },
  {
    title: "Decentrify - Decentralised Chat",
    image: Decentrify,
    text: "Best Blockchain Project @ IETE-VIT AccessDenied'21. It has the features to create accounts & interact with the other users through public chat wall. Worked on frontend development & Smart Contract integration.",
    github: "https://github.com/FreakyTalons/DWebApp",
    hosting: "https://abuzarbagewadi.github.io/DWebApp/",
    techStackSrc: [HTML_src, CSS_src, JS_src, Solidity_src],
  },
  {
    title: "E-Commerce Platform",
    image: EcomInterface,
    text: "A complete e-com platform with options to create an account, sign-up/sign-in, add/remove items in cart and wish list, place orders. An admin panel to add/ edit products in the listing and review orders.",
    github: "",
    hosting: "",
    techStackSrc: [React_src, Redux_src, RestAPI, CSS_src],
  },
];

const rightCardProjects = [
  {
    title: "SimpliHR",
    image: SimpliHR,
    text: "A Comprehensive platform that manages attendance in real time via facial recognition; predicts attrition probability via web hosted logistic regression model; collects data for prediction & ensures authenticated access.",
    github: "https://github.com/FreakyTalons/SimpliHR",
    hosting: "",
    techStackSrc: [NextImg, Firebase_src, OpenCV_src, FastAPI_src],
  },
  {
    title: "Blog Editor",
    image: BlogEditor,
    text: "A blog post platform with an editor to create, edit and preview blogs that supports text as well as media and an option to submit to an external API in order to publish them over a listing platform with pertinent tags.",
    github: "https://github.com/FreakyTalons/blog-interface",
    hosting: "https://blog-interface-nu.vercel.app/",
    techStackSrc: [React_src, Node_src, CSS_src],
  },
  {
    title: "Spin the Wheel",
    image: SpinWheel,
    text: "A highly responsive Spin the Wheel interface that verifies the validity of the user's details and allows them to spin a wheel to land a random discount offer and redeem it by copying the code to clipboard.",
    github: "https://github.com/FreakyTalons/engageBud-spinwheel",
    hosting: "https://spin-wheel-game.vercel.app/",
    techStackSrc: [React_src, Tailwind_src],
  },
];

const Row0 = () => {
  const scrollRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ["start end", "end center"],
  });

  const opacity = useTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    [0, 0, 1, 1, 0]
  );

  return (
    <>
      <motion.div
        className={`${style.row_wrapper} w-100 align-items-center justify-content-center d-none d-xl-flex`}
        ref={scrollRef}
        style={{
          opacity,
        }}
      >
        <Row className="w-100 align-items-center justify-content-center">
          <ProjectCardLeft item={leftCardProjects[0]} />
          <ProjectCardRight item={rightCardProjects[0]} />
        </Row>
      </motion.div>
      <div
        className={`${style.row_wrapper} w-100 align-items-center justify-content-center d-flex d-xl-none`}
      >
        <Row className="w-100 align-items-center justify-content-center">
          <ProjectCardLeft item={leftCardProjects[0]} />
          <ProjectCardRight item={rightCardProjects[0]} />
        </Row>
      </div>
    </>
  );
};

const Row1 = () => {
  const scrollRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ["start end", "end center"],
  });

  const opacity = useTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    [0, 0, 1, 1, 0]
  );

  return (
    <>
      <motion.div
        className={`${style.row_wrapper} w-100 align-items-center justify-content-center d-none d-xl-flex`}
        ref={scrollRef}
        style={{
          opacity,
        }}
      >
        <Row className="w-100 align-items-center justify-content-center">
          <ProjectCardLeft item={leftCardProjects[1]} />
          <ProjectCardRight item={rightCardProjects[1]} />
        </Row>
      </motion.div>
      <div
        className={`${style.row_wrapper} w-100 align-items-center justify-content-center d-flex d-xl-none`}
      >
        <Row className="w-100 align-items-center justify-content-center">
          <ProjectCardLeft item={leftCardProjects[1]} />
          <ProjectCardRight item={rightCardProjects[1]} />
        </Row>
      </div>
    </>
  );
};

const Row2 = () => {
  const scrollRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ["start end", "end center"],
  });

  const opacity = useTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    [0, 0, 1, 1, 0]
  );

  return (
    <>
      <motion.div
        className={`${style.row_wrapper} w-100 align-items-center justify-content-center d-none d-xl-flex`}
        ref={scrollRef}
        style={{
          opacity,
        }}
      >
        <Row className="w-100 align-items-center justify-content-center">
          <ProjectCardLeft item={leftCardProjects[2]} />
          <ProjectCardRight item={rightCardProjects[2]} />
        </Row>
      </motion.div>
      <div
        className={`${style.row_wrapper} w-100 align-items-center justify-content-center d-flex d-xl-none`}
      >
        <Row className="w-100 align-items-center justify-content-center">
          <ProjectCardLeft item={leftCardProjects[2]} />
          <ProjectCardRight item={rightCardProjects[2]} />
        </Row>
      </div>
    </>
  );
};

const Row3 = () => {
  const scrollRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ["start end", "end center"],
  });

  const opacity = useTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    [0, 0, 1, 1, 0]
  );

  return (
    <>
      <motion.div
        className={`${style.row_wrapper} w-100 align-items-center justify-content-center d-none d-xl-flex`}
        ref={scrollRef}
        style={{
          opacity,
        }}
      >
        <Row className="w-100 align-items-center justify-content-center">
          <ProjectCardLeft item={leftCardProjects[3]} />
        </Row>
      </motion.div>
      <div
        className={`${style.row_wrapper} w-100 align-items-center justify-content-center d-flex d-xl-none`}
      >
        <Row className="w-100 align-items-center justify-content-center">
          <ProjectCardLeft item={leftCardProjects[3]} />
        </Row>
      </div>
    </>
  );
};

export default function ProjectPage() {
  return (
    <>
      <Container
        className={`${style.project_section} d-flex align-items-center justify-content-center flex-column`}
      >
        <Row0 />
        <Row1 />
        <Row2 />
        <Row3 />
      </Container>
    </>
  );
}
