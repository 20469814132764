//stylesheet import
import style from "../assets/styles/skills.module.css";

//react import
import React, { useRef } from "react";

//react-bootstrap import
import Container from "react-bootstrap/Container";

//framer-motion import
import {
  motion,
  useTransform,
  useMotionValue,
  useAnimationFrame,
  useScroll,
} from "framer-motion";
import { wrap } from "@motionone/utils";

function ParallaxText0({ children, baseVelocity = 90 }) {
  const baseX = useMotionValue(0);

  const velocityFactor = 0.75;

  const x = useTransform(baseX, (v) => `${wrap(50, 70, v)}%`);
  useAnimationFrame((t, delta) => {
    let moveBy = baseVelocity * (delta / 1500);
    moveBy += moveBy * velocityFactor;

    baseX.set(baseX.get() + moveBy);
  });

  return (
    <div className={`${style.parallax}`}>
      <motion.div className={`${style.scroller}`} style={{ x }}>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
      </motion.div>
    </div>
  );
}

function ParallaxText1({ children, baseVelocity = 90 }) {
  const baseX = useMotionValue(0);

  const velocityFactor = 0.375;

  const x = useTransform(baseX, (v) => `${wrap(-10, -30, v)}%`);
  useAnimationFrame((t, delta) => {
    let moveBy = baseVelocity * (delta / 1500);
    moveBy += moveBy * velocityFactor;

    baseX.set(baseX.get() + moveBy);
  });

  return (
    <div className={`${style.parallax}`}>
      <motion.div className={`${style.scroller}`} style={{ x }}>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
      </motion.div>
    </div>
  );
}

function ParallaxText2({ baseVelocity = 90 }) {
  const baseX = useMotionValue(0);

  const velocityFactor = 0.375;

  const x = useTransform(baseX, (v) => `${wrap(-14, 14, v)}%`);
  useAnimationFrame((t, delta) => {
    let moveBy = baseVelocity * (delta / 1500);
    moveBy += moveBy * velocityFactor;

    baseX.set(baseX.get() + moveBy);
  });

  return (
    <div className={`${style.parallax}`}>
      <motion.div className={`${style.scroller}`} style={{ x }}>
        <span>
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg"
            alt="Bootstrap"
          />
        </span>

        <span>
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/c/c-original.svg"
            alt="C"
          />
        </span>

        <span>
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/cplusplus/cplusplus-original.svg"
            alt="C++"
          />
        </span>

        <span>
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg"
            alt="CSS3"
          />
        </span>

        <span>
          <i class="devicon-express-original mb-0"></i>
        </span>

        <span>
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg"
            alt="Figma"
          />
        </span>

        <span>
          <img
            alt="git"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg"
          />
        </span>

        <span>
          <i class="devicon-react-original colored mb-0"></i>
        </span>

        <span>
          <i class="devicon-redux-original colored mb-0"></i>
        </span>

        <span>
          <i class="devicon-github-original mb-0"></i>
        </span>

        <span>
          <img
            alt="html5"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg"
          />
        </span>

        <span>
          <img
            alt="javascript"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg"
          />
        </span>

        <span>
          <img
            alt="jquery"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jquery/jquery-original.svg"
          />
        </span>

        <span>
          <img
            alt="material ui"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/materialui/materialui-original.svg"
          />
        </span>

        <span>
          <img
            alt="mongo"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original-wordmark.svg"
          />
        </span>

        <span>
          <i class="devicon-mysql-plain-wordmark mb-0"></i>
        </span>

        <span>
          <i class="devicon-nextjs-original mb-0"></i>
        </span>
      </motion.div>
    </div>
  );
}

function ParallaxText3({ baseVelocity = 90 }) {
  const baseX = useMotionValue(0);

  const velocityFactor = 0.375;

  const x = useTransform(baseX, (v) => `${wrap(14, -14, v)}%`);
  useAnimationFrame((t, delta) => {
    let moveBy = baseVelocity * (delta / 1500);
    moveBy += moveBy * velocityFactor;

    baseX.set(baseX.get() + moveBy);
  });

  return (
    <div className={`${style.parallax}`}>
      <motion.div className={`${style.scroller}`} style={{ x }}>
        <span>
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg"
            alt="Bootstrap"
          />
        </span>

        <span>
          <i class="devicon-npm-original-wordmark mb-0"></i>
        </span>

        <span>
          <i class="devicon-pandas-original-wordmark mb-0"></i>
        </span>

        <span>
          <img
            alt="python"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg"
          />
        </span>

        <span>
          <i class="devicon-react-original colored mb-0"></i>
        </span>

        <span>
          <i class="devicon-redux-original colored mb-0"></i>
        </span>

        <span>
          <i class="devicon-sass-original mb-0"></i>
        </span>

        <span>
          <i class="devicon-django-plain mb-0"></i>
        </span>

        <span>
          <img
            alt="firebase"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain.svg"
          />
        </span>

        <span>
          <i class="devicon-spring-plain-wordmark mb-0"></i>
        </span>

        <span>
          <i class="devicon-tailwindcss-plain colored mb-0"></i>
        </span>

        <span>
          <img
            alt="typescript"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg"
          />
        </span>

        <span>
          <img
            alt="vs code"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg"
          />
        </span>

        <span>
          <img
            alt="open cv"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/opencv/opencv-original.svg"
          />
        </span>

        <span>
          <img
            alt="fast api"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/fastapi/fastapi-original.svg"
          />
        </span>

        <span>
          <img
            alt="adobe xd"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/xd/xd-plain.svg"
          />
        </span>
      </motion.div>
    </div>
  );
}

export default function ProjectPortfolio() {
  const scrollRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ["start end", "end start"],
  });

  const opacity = useTransform(
    scrollYProgress,
    [0, 0.1, 0.4, 0.5, 0.6, 0.85],
    [0, 0, 1, 1, 0.5, 0]
  );

  const scale = useTransform(
    scrollYProgress,
    [0, 0.55, 0.6, 1],
    [1, 1, 1.75, 5]
  );

  return (
    <>
      <Container
        className={`${style.skills_section} d-flex align-items-center justify-content-center`}
        id="technology-stack"
      >
        <motion.div
          ref={scrollRef}
          className={`${style.animations_wrapper} d-flex align-items-center justify-content-center flex-column`}
          style={{
            opacity,
            scale,
          }}
        >
          <section className={`${style.logo_line}`}>
            <ParallaxText2 baseVelocity={2}></ParallaxText2>
          </section>

          <section className={`${style.title_txt}`}>
            <ParallaxText0 baseVelocity={-3}>Projects</ParallaxText0>
            <ParallaxText1 baseVelocity={2}>Portfolio</ParallaxText1>
          </section>

          <section className={`${style.logo_line}`}>
            <ParallaxText3 baseVelocity={-2}></ParallaxText3>
          </section>
        </motion.div>
      </Container>
    </>
  );
}
